import React, {Component} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from 'react-router-dom';
import {getUserData} from '../userUtils';

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            headerText: '',
            errors: {}
        };
        this.handleGtU();
        this.handleSettingGet();
    }

    handleSettingGet = () => {

        axios.get('constant/list', this.state)
            .then(response => {
                const constantData = response.data.data
                this.setState({headerText: constantData.settingData.headerText});
            })
            .catch(error => {
            });

    }
    handleLogout = () => {
        const token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('user/logout', this.state)
            .then(response => {
                console.log('aaaa')
                localStorage.removeItem("isAuthenticated");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                window.location.href = '/login';
                //this.props.history.push('/login');
                //window.location.reload();
                toast.success(response.data.meta.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
            .catch(error => {
                console.log('bbbbbbb')
                localStorage.removeItem("isAuthenticated");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                window.location.href = '/login';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });

    }
    handleGtU = () => {
        const token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.post('user/view-profile', this.state)
            .then(response => {
                localStorage.setItem("userData", JSON.stringify(response.data.data));
            })
            .catch(error => {
                localStorage.removeItem("isAuthenticated");
                localStorage.removeItem("token");
                localStorage.removeItem("userData");
                window.location.href = '/login';
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            });

    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
        // document.querySelector('body').classList.toggle('mobi-menu-active');
    }

    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }

    toggleOnsearch() {
        document.querySelector('.mobi-search-toggle').classList.toggle('active');
        document.querySelector('.noti-iconbox').classList.toggle('active');
    }

    render() {
        const userData = getUserData();
        return (
            <nav className="navbar p-0 fixed-top d-flex flex-row">
                <ToastContainer/>
                <div className='mobi-menu-icon hide'>
                    <button className="navbar-toggler navbar-toggler-left d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
                        <i className='las la-bars'></i>
                    </button>
                </div>
                <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/">
                        <img src={require('../../assets/images/sitelogo.png')} alt="logo"/>
                    </Link>
                </div>
                <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch pl-0">
                    <div className="toggler-head-btnbox">
                        <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
                            <i class="las la-bars"></i>
                        </button>
                    </div>


                    <ul className="navbar-nav navbar-nav-right">
                        {/* <li className='nav-item search-mobi hide'>
                            <div className='noti-iconbox' onClick={this.toggleOnsearch}><i class="las la-search"></i></div>
                        </li> */}

                        <li className='nav-item nav-link search-mobi'>
                            <div className='noti-iconbox d-flex'><i className='fa fa-bell-o'></i></div>
                        </li>

                        {/* <Dropdown alignRight as="li" className="nav-item">
                            <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer" title="Notifications">
                            <div className='noti-iconbox d-flex'>
                                <i className='fa fa-bell-o'></i>
                            </div>
                        </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list notifications-list">
                                <h6 className="notifi-head mb-0 d-flex v-center"><span><Trans>Notifications</Trans></span><Link className="view_more ml-auto" to="/">View More</Link></h6>
                                <div className='media-list scrollable-container'>

                                <div className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                    <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                    <Link className="text-left notification-content" to="/">
                                        <span>We have introduced new features and updates this February to meet your accounting needs. Click to read what's new in Munim ERP.</span>
                                    </Link>
                                    </div>
                                    <div className='remove_icon'>
                                    <i class="las la-times"></i>
                                    </div>
                                </div>

                                <div className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                    <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                    <Link className="text-left notification-content" to="/">
                                        <span><b>Congratulations</b>, Now you can generate more eWay / eInvoice bill with <b>200 free</b> credits. We have <b>added 200 GST credits</b> to your account. Hurry Up!!! Credits are valid till 25th May 2023.</span>
                                    </Link>
                                    </div>
                                    <div className='remove_icon'>
                                    <i class="las la-times"></i>
                                    </div>
                                </div>
                                
                                <div className='list-item align-items-center justify-content-between notify-responsive noti-items'>
                                    <div className='me-1 d-flex align-items-start gap-1 mob-me-0'>
                                    <Link className="text-left notification-content" to="/">
                                        <span>How to create E-way/E-invoice Portal API User credentials?</span>
                                    </Link>
                                    </div>
                                    <div className='remove_icon'>
                                    <i class="las la-times"></i>
                                    </div>
                                </div>

                                </div>
                            </Dropdown.Menu>
                        </Dropdown> */}

                        <Dropdown alignRight as="li" className="nav-item ml-2 head-prof-item">
                            <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret ml-0 mr-0">
                                <div className="navbar-profile">
                                    <img className="img-xs rounded-circle" src={userData.companyLogo} alt="profile"/>
                                    <p className="mb-0 d-none d-sm-block navbar-profile-name">
                                        <span className='name-text'><Trans>{userData.name}</Trans></span>
                                    </p>
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                                <Dropdown.Item href="/edit-profile" className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="fa fa-user-circle"></i>
                                        <span><Trans>Profile Setting</Trans></span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item href="/change-password" className="preview-item">
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="fa fa-lock"></i>
                                        <span><Trans>Update Password</Trans></span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={this.handleLogout} className="preview-item">
                                    {/*onClick={evt =>evt.preventDefault()}*/}
                                    <div className="drop-icon-item d-flex v-center">
                                        <i class="fa fa-sign-out"></i>
                                        <span><Trans>Log Out</Trans></span>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </div>

            </nav>
        );
    }
}

export default Navbar;
